function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
var _excluded = ["instantSearchInstance", "widgetParams"],
  _excluded2 = ["widgetParams"];
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
import { useMemo, useRef, useState } from 'react';
import { dequal } from "../lib/dequal.js";
import { getIndexSearchResults } from "../lib/getIndexSearchResults.js";
import { useIndexContext } from "../lib/useIndexContext.js";
import { useInstantSearchContext } from "../lib/useInstantSearchContext.js";
import { useInstantSearchServerContext } from "../lib/useInstantSearchServerContext.js";
import { useInstantSearchSSRContext } from "../lib/useInstantSearchSSRContext.js";
import { useStableValue } from "../lib/useStableValue.js";
import { useWidget } from "../lib/useWidget.js";
export function useConnector(connector) {
  var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var additionalWidgetProperties = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var serverContext = useInstantSearchServerContext();
  var ssrContext = useInstantSearchSSRContext();
  var search = useInstantSearchContext();
  var parentIndex = useIndexContext();
  var stableProps = useStableValue(props);
  var stableAdditionalWidgetProperties = useStableValue(additionalWidgetProperties);
  var shouldSetStateRef = useRef(true);
  var previousRenderStateRef = useRef(null);
  var previousStatusRef = useRef(search.status);
  var widget = useMemo(function () {
    var createWidget = connector(function (connectorState, isFirstRender) {
      // We skip the `init` widget render because:
      // - We rely on `getWidgetRenderState` to compute the initial state before
      //   the InstantSearch.js lifecycle starts.
      // - It prevents UI flashes when updating the widget props.
      if (isFirstRender) {
        shouldSetStateRef.current = true;
        return;
      }

      // There are situations where InstantSearch.js may render widgets slightly
      // after they're removed by React, and thus try to update the React state
      // on unmounted components. React 16 and 17 consider them as memory leaks
      // and display a warning.
      // This happens in <DynamicWidgets> when `attributesToRender` contains a
      // value without an attribute previously mounted. React will unmount the
      // component controlled by that attribute, but InstantSearch.js will stay
      // unaware of this change until the render pass finishes, and therefore
      // notifies of a state change.
      // This ref lets us track this situation and ignore these state updates.
      if (shouldSetStateRef.current) {
        var instantSearchInstance = connectorState.instantSearchInstance,
          widgetParams = connectorState.widgetParams,
          renderState = _objectWithoutProperties(connectorState, _excluded);

        // We only update the state when a widget render state param changes,
        // except for functions. We ignore function reference changes to avoid
        // infinite loops. It's safe to omit them because they get updated
        // every time another render param changes.
        if (!dequal(renderState, previousRenderStateRef.current, function (a, b) {
          return (a === null || a === void 0 ? void 0 : a.constructor) === Function && (b === null || b === void 0 ? void 0 : b.constructor) === Function;
        }) || instantSearchInstance.status !== previousStatusRef.current) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          setState(renderState);
          previousRenderStateRef.current = renderState;
          previousStatusRef.current = instantSearchInstance.status;
        }
      }
    }, function () {
      // We'll ignore the next state update until we know for sure that
      // InstantSearch.js re-inits the component.
      shouldSetStateRef.current = false;
    });
    return _objectSpread(_objectSpread({}, createWidget(stableProps)), stableAdditionalWidgetProperties);
  }, [connector, stableProps, stableAdditionalWidgetProperties]);
  var _useState = useState(function () {
      if (widget.getWidgetRenderState) {
        var _widget$getWidgetSear;
        // The helper exists because we've started InstantSearch.
        var helper = parentIndex.getHelper();
        var uiState = parentIndex.getWidgetUiState({})[parentIndex.getIndexId()];
        helper.state = ((_widget$getWidgetSear = widget.getWidgetSearchParameters) === null || _widget$getWidgetSear === void 0 ? void 0 : _widget$getWidgetSear.call(widget, helper.state, {
          uiState: uiState
        })) || helper.state;
        var _getIndexSearchResult = getIndexSearchResults(parentIndex),
          results = _getIndexSearchResult.results,
          scopedResults = _getIndexSearchResult.scopedResults,
          recommendResults = _getIndexSearchResult.recommendResults;

        // We get the widget render state by providing the same parameters as
        // InstantSearch provides to the widget's `render` method.
        // See https://github.com/algolia/instantsearch/blob/019cd18d0de6dd320284aa4890541b7fe2198c65/src/widgets/index/index.ts#L604-L617
        var _widget$getWidgetRend = widget.getWidgetRenderState({
            helper: helper,
            parent: parentIndex,
            instantSearchInstance: search,
            results: widget.dependsOn === 'recommend' && recommendResults && ssrContext ? recommendResults[ssrContext.recommendIdx.current++] : results,
            scopedResults: scopedResults,
            state: helper.state,
            renderState: search.renderState,
            templatesConfig: search.templatesConfig,
            createURL: parentIndex.createURL,
            searchMetadata: {
              isSearchStalled: search.status === 'stalled'
            },
            status: search.status,
            error: search.error
          }),
          widgetParams = _widget$getWidgetRend.widgetParams,
          renderState = _objectWithoutProperties(_widget$getWidgetRend, _excluded2);
        return renderState;
      }
      return {};
    }),
    _useState2 = _slicedToArray(_useState, 2),
    state = _useState2[0],
    setState = _useState2[1];
  useWidget({
    widget: widget,
    parentIndex: parentIndex,
    props: stableProps,
    shouldSsr: Boolean(serverContext)
  });
  return state;
}